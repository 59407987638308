nav.navbar {

   // background-color: #fff !important;

    @media #{$max-small} {
        padding-top: 0;

        .navbar-toggler {
            padding: .15rem .55rem
        }
    }  
    .search-set {
        width: 100%;
        position: unset;
        margin-top: 10px;
    }  
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 180px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        text-transform:capitalize;
        padding-top: 10px;
        padding-bottom: 10px;
       // margin-top: 20px;
      }
      
      .dropdown-content a {
        float: none;
       color: #294B29;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
      
      .dropdown-content a:hover {
        background-color: #ddd;
      }
      
      .dropdown:hover .dropdown-content {
        display: block;
      }

    .p-dropdown {

        background: transparent;
        margin-top: -5px;
        border: 0;

        .p-dropdown-label.p-inputtext.p-placeholder {
            padding-left: 10px;
            padding-right: 20px;
        }
        .p-dropdown-trigger {
            width: 2rem;
            span {
                font-size: 13px;
                padding-top: 3px;
            }
        }
        &:not(.p-disabled).p-focus {
            border: unset;
            box-shadow: unset;
        }

        * {
            color: #fff !important;
            font-weight: 600;
        }
    }

    .navbar-nav {

        a {
            font-weight: 600;
            margin-left: 15px;
            margin-right: 15px;
            font-size: 12px;
            margin-top: 5px;
            padding-bottom: 3px;

            @media #{$min-small} {
                font-size: 16px;
            }
            @media #{$min-large} {
                font-size: 13px;
            }
            @media #{$min-Xlarge} {
                font-size: 15px;
            }
        }
        & > a, & > li a {
            transition: all .1s ease-in-out;
            border-bottom: 3px solid transparent;
            &.active, &:hover {border-color: $main-color}
            @media #{$max-med} {
              //  margin-bottom: 25px;
            }
        }
        .header-options {
            @media #{$max-med} {
                order: -1;
                margin-bottom: 35px;
        

            }
            a {
                border: 0px solid $main-color;
                box-sizing: border-box;
                color: #fff !important;
                background-color: #789461;
               // padding: 5px;
                margin-inline-start: 20px;
                margin-inline-end: 0;
                border-radius: 40px;
                text-transform: uppercase;
                padding: 0.5rem 18px;
                font-size: 14px;
                font-weight: 600;
            }
            a:hover {
                background-color:#667f51;
                color: white;
              }
        }
    
    }

    .navbar-collapse {
        @media #{$max-med} {
            background-color: $bg-color;
            a {color: $text-dark}
            padding-top: 25px;
            margin-top: 10px;
            border: 1px solid #dbd4d4;
        }
    }

//  &.first-nav {
//       background-color: $main-color !important;

//         .header-options {
//             justify-content: space-between;

//             .soc-links {
//                 display: flex;
//                 align-items: center;
//                 i {
//                     margin-inline-end: 20px;
//                 }
//             }
//         }

//         .forget-btn {
//             margin-inline-start: auto;
//             margin-inline-end: 20px;
//             margin-top: -2px;
//             font-size: $xs-size;
//         }
//     }
   
    &.second-nav {
        width: 100%;
       // * {color: #fff !important}
        .navbar-brand img {
            @media #{$max-small} {height: 40px; width: 40px;}
        }     
    }

    .welcome-msg {color: #fff}

}

.custom-navs .navbar {
 
    &.second-nav {
        background-color: #0005!important;
        top: 0px;
        z-index: 1;
        position: fixed;
        z-index: 1030;
        //margin-right: 100px;
        
        transition: 0.2s;
        //absolute
        .navbar-nav > a {color: black !important}
        // @media #{$min-med} {
        //     top: 51px
        // }
        @media #{$min-large} {
            .navbar-nav > a {color: #fff !important}
        }
       
    }
   
    .navbar {
        border: 0;
        font-size: 14px;
        transition: all .4s;
        -webkit-transition: all .4s;
        padding: 0;
        background: #fff;
        box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
       // margin-right: 100px;
    }

}

.header-panel .p-dropdown-items {
    padding: 0;
    position: relative;
  //display: inline-block;
  float: left;
  //display: none;
  position: absolute;
}
.p-dropdown-items  {
   // display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    float:initial;
    clear: both;
    margin-top: 30px;
  }

.button-en  {
    padding: -5px;
    margin-left: 20px;
    //margin-right: -30px;
   //margin-top: -30px;
   float:none;
   clear: none;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    right: 0px;
    background:#294B29;
    padding: 7px;
    //margin-right: 20%;
   
  }
$main-color: #789461;
$side-color: #50623A;

$text-light: #888888;
$text-dark: #3A3A3A;

$border-color: #eaeaea;

$bg-color: #f8f9fb;

$part-color: #333333;

$xs-size: clamp(10px, 1.5vw , 14px);
$sm-size: clamp(12px, 2vw , 16px);
$md-size: clamp(14px, 2.5vw , 18px);
$lg-size: clamp(16px, 3vw , 20px);
$xl-size: clamp(18px, 3.5vw , 26px);


:root {
    --dir-left: left;
    --dir-right: right;
}


// media queries

$min-small: "(min-width: 576px)";

// Medium devices (tablets, 768px and up
$min-med: "(min-width: 768px)";

// Large devices (desktops, 992px and up)
$min-large: "(min-width: 992px)";

// Extra large devices (large desktops, 1200px and up)
$min-Xlarge: "(min-width: 1200px)";


$max-mobile: "(max-width: 575.98px)";

$max-small: "(max-width: 767.98px)";

// Medium devices (tablets, less than 992px)
$max-med: "(max-width: 991.98px)";

// Large devices (desktops, less than 1200px)
$max-large: "(max-width: 1199.98px)";
.category-page {

    .content-upper h2 {
        font-size: $xl-size;
    }

    .cards-wrapper {
        .card {

            background-color: transparent;
            border: 0;

            h3 {
                font-size: clamp(16px, 2vw, 20px);
                max-height: 145px;
                overflow: hidden;
            }
            span {
                font-size: clamp(12px, 1vw, 14px);
                color: $text-light;
            }

            .card-content {
                min-height: 100px;
                align-items: center;

                .card-down {
                    * {display: block; margin: auto; text-align: center;color: $main-color;font-weight: 600;}
                    img {
                        max-height: clamp(20px, 1.5vw, 30px);
                        margin-bottom: 7px;
                    }
                }
            }
        }
    }
}

* //{
//   text-transform: capitalize;

//   &::placeholder, select {
//     text-transform: capitalize !important;
//   }
  
// }
#root {
  display: flex;
  min-height: 100px;
 flex-direction: column;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  font-weight: bold;
}
ul {
  list-style: none;
}
a {
  text-decoration: none !important;
  &, &:hover {color: #fff}
  cursor: pointer;
}
a, button {
  &, &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}
button a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
}
input, textarea {
  outline: 0;
  text-transform: none;
  &:-webkit-autofill {
    -webkit-text-fill-color: $text-light;
    &:focus {
      -webkit-text-fill-color: $text-light;
    }
    &:hover {
      -webkit-text-fill-color: $text-light;
    }
  }
}
textarea {
  resize: none;
}

// colors
.main-color {
  color: $main-color !important;
}
.side-color {
  color: $side-color !important;
}
.bg-main-Color {
  background: $main-color !important;
  color: #FFF;
}
.btn-color {
  background: $side-color;
  color: #FFF;
}
.title-light {
  color: $text-light
}
.title-light {
  color: $text-dark
}
.white {
  color: #fff;
}


// override vendors
.p-paginator.p-component {
  padding: 10px;
  //position: absolute;
  bottom: 100px;
  width: 100%;
  margin: 10px 0px 0px;
  .p-paginator-pages .p-paginator-page.p-highlight {
    background: $main-color;
    border-color: $main-color;
    color: #fff;
  }

  .p-paginator-page, .p-paginator-element {
    &:hover {
      color: #fff !important;
      background-color: $main-color !important;
    }
  }

  .p-dropdown-label.p-inputtext {
    font-size: $xs-size;
  }

  @media #{$max-med} {
    width: 100%;
    left: 0;
  }
}
.p-paginator button.p-paginator-element {
  @media #{$max-mobile} {
    min-width: 25px !important;
    font-size: 10px !important;
    span {
      font-size: 10px !important;
    }
  }
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0.5rem !important;
}
.p-message .p-message-wrapper {
  font-size: 16px;
  @media #{$max-small} {
    font-size: 12px;
  }
}
.p-toast {
  max-width: 80%;
  z-index: 17;
  font-size: $sm-size;
  .p-toast-message-icon {font-size: $xl-size !important}
  .p-toast-icon-close span {font-size: $md-size !important}
  .p-toast-message-content {align-items: center;}
  .p-toast-detail {padding-inline-start: 15px; margin-top: 0 !important;}
  
}
.p-dropdown-clear-icon {
  left: 2.357rem !important;
  right: unset !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset;
  border-color: unset;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: start;
}
.p-datatable-table {
  font-size: $sm-size;
}
.homepage{
  input[type=text] {
    width: 170px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    position: absolute;
    background-color: white;
    //margin-top: 6%;
    @media #{$min-small} {
                         
      margin-top: 100px;
  }
  @media #{$max-small}{
    margin-top: 60px;
  }
   
    margin-left: 2%;
    margin-right: 2%;
   // background-image:url('https://file+.vscode-resource.vscode-cdn.net/Users/Nada/Freelance/apa-front/src/assets/media/search.png?version%3D17112256037/');
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  }
  
  input[type=text]:focus {
    width: 300px;
  }
}
input[type=text] {
  width: 170px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  //margin-top: 6%;
 // background-image:url('https://file+.vscode-resource.vscode-cdn.net/Users/Nada/Freelance/apa-front/src/assets/media/search.png?version%3D17112256037/');
  background-repeat: no-repeat;
 // padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

input[type=text]:focus {
  width: 300px;
}

// helpers
.reset-button {
  border: 0;
  background: none;
  margin-bottom: 12px;
  line-height: 1 !important;
}
.reset-a {
  color: $text-light !important;
}
.reset-bold {
  font-weight: 200;
}
.flip-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.disabled-overlay {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
    opacity: 0.6;
  }
}
.no-cursor {
  cursor: unset !important;
}
.no-drop {
  cursor: no-drop;
}
.cursor-pointer {
  cursor: pointer;
}
.child-capital * {
  text-transform: capitalize;
}
.ms-auto {
  margin-inline-start: auto;
}
.me-auto {
  margin-inline-end: auto;
}
.ms-2 {
  margin-inline-start: 10px;
}
.me-2 {
  margin-inline-end: 10px;
}
.ms-4 {
  margin-inline-start: 25px;
}
.me-4 {
  margin-inline-end: 25px;
}
.ps-2 {
  padding-inline-start: 10px;
}
.pe-2 {
  padding-inline-end: 10px;
}
.centric {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.max-text {
  overflow: auto;
  max-height: 200px;
  word-break: break-word;
  scrollbar-width: none;
  &::-webkit-scrollbar {
      display: none;
  }
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}

// gloabl classes
body {
  text-align: start;
  //background-color: #f7f9f4;
  background-color: #f5f5f5;
}
.global-head {
  font-size: 35px;
  color: #4a4a4a;
  font-family: RobotoMedium;

  @media #{$max-med} {
    font-size: 25px !important;
  }

  @media #{$max-mobile} {
    font-size: 20px !important;
  }
}
.container-fluid {
  
  @media (min-width: 876px){
    padding-right: 35px;
    padding-left: 35px;
  }

  @media screen and (min-width: 1920px) {
    //For Projectors or Higher Resolution Screens (Full HD)
        width: 1899px;
        margin-left: auto;
        margin-right: auto;
    }
}
.main-button {
  border-radius: 0.2rem;
  color: white !important;
  background-color: #6fb048;
  width: 170px;
  min-height: 35px;
  outline: 0;
  border: #6fb048;
  text-transform: capitalize;
  font-size: $sm-size;

  font-weight: 500;
  display: block;
  padding: 11px;

  @media #{$max-med} {
    padding: 10px;
  }

  @media #{$max-small} {
    padding: 8px;
  }

  @media #{$max-mobile} {
    padding: 6px;
  }

  i {
    padding: 5px;
  }
  

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:active {
    background-color: $side-color !important;
    border-color: $side-color !important;
  }
  &.bg-main-Color:hover,
  &.bg-main-Color:active {
    background-color: $main-color !important;
  }
  &.gray-button {
    &, &:hover, &:active {background-color: $bg-color !important}
    color: $text-dark !important;
  }

}
.borderd-button {
  border-radius: 0.2rem;
  border: solid 1px #fff;
  width: 135px;
  min-height: 35px;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}
.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff8c;
  top: 0;
  left: 0;
  z-index: 18;

  i {
    transform: translateY(-50%);
    top: 50%;
    position: fixed;
    left: 48%;

    @media #{$max-small} {
      left: 47%;
    }
    @media #{$max-mobile} {
      left: 46%;
    }
  }
}
.cookies-container {
  .cookies-content {
    flex: unset !important;
    @media #{$max-mobile} {
      padding-top: 10px;
    }
  }
  * {
    @media #{$max-mobile} {
      font-size: 12px !important;
    }
  }
}
.main-border {
  @media #{$max-med} {
    order: -1;
    margin-bottom: 35px;
  }
     //border-bottom: 3px solid $main-color;
     //padding-bottom: 3px;
     
      border: 1px solid $main-color;
      box-sizing: border-box;
      color: #AA5656 !important;
      background-color: #fff;
     // padding: 5px;
      margin-inline-start: 20px;
      margin-inline-end: 0;
      border-radius: 40px;

      padding: 0.5rem 18px;
      font-size: 14px;
      font-weight: 600;
     // margin-left: 320px;

}
.main-border:hover {
  background-color:#ededed ;
 color: white;

}
.head-style {
    //color: #fff;
    font-family: RobotoRegular;
    font-size: clamp(18px, 2.5vw, 38px);
    &.dark {color: $text-dark;}
}
.bg-size {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.headS-responsive {
  font-size: clamp(12px, 2vw, 20px);
}
.section-mr {
  margin-top: 35px;
  @media #{$min-small} {
      margin-top: 45px;
  }
  @media #{$min-med} {
     // margin-top: 200px;
     // border-radius: 200px 0px 0px 0px;

  }
}
.content-wrapper {
  flex: 1 1;
 //position: relative;
  position:unset;
}
.go-home {
  border: 3px solid #08A350;
  padding: 5px 10px;
  &, &:hover {
    color: $text-dark;
  }
}
.input-style {
  color: #83a7a5;
  border-radius: 4px;
  border: solid 1px #c1d3d2;

  &.upload {
    border: dashed 1px rgba(166, 166, 166, 0.2);
    height: 80px;
    text-align: center;
    margin-bottom: 0 !important;
  }

  &:focus-visible {outline: 0 !important}

  padding: 7px 9px;

  @media #{$min-small} {
    padding: 10px;
  }

  &::placeholder {
    font-size: $sm-size;
  }
}
.custom-error-msg {
  display: block;
  font-size: $xs-size;
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
  padding: 10px;
  margin: 5px 0 5px;
}
.main-form-style {
  input, textarea {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);
      &, &::placeholder {
          color: #83a7a5;
          font-size: $xs-size;
      }
  }
  button {
      width: 60%;
      @media #{$min-small} {
          width: 190px;
      }
  }
  span.file-name {
    color: #8d8c8d;
    font-size: 15px;
    margin: 0px 15px;
  }

  .p-fileupload-buttonbar,
  .p-progressbar,
  .p-fileupload-row {
    display: none;
  }
  // .p-fileupload {
  //   @media #{$max-mobile} {
  //     display: none !important;
  //   }
  // }
  .p-fileupload-content {
    position: absolute;
    text-align: center;

    width: 50%;
    height: 80px;
    
    z-index: 1;
    padding: 19px !important;
    background: transparent !important;
    border: 0 !important;
  }

}
.no-da {
  font-size: $sm-size;
}
.remove-button {
  font-size: $xs-size;
}
.custom-dialog {
  width: 80vw;
  @media #{$min-small} {width: 70vw;}
  @media #{$min-med} {width: 60vw;}
  @media #{$min-large} {width: 40vw;}
  .p-dialog-title {
      font-weight: bold !important;
      font-size: $lg-size !important;
  }
  label {font-size: $sm-size}
}
.search-set {
  @media #{$min-large} {
      //left: 100px;
      top: -60px;
      width: 300px;
      position:inherit;
   
      border: 1px solid grey;
      
      
      *, .p-inputtext, .p-button.p-button-secondary, .p-button.p-button-secondary:hover, .p-button-icon {background-color: $bg-color !important}
  }
  .p-inputtext, .p-button.p-button-secondary, .p-button.p-button-secondary:hover, .p-button-icon {
      border: 1px solid $bg-color;

  }
  .p-button-icon {color: $text-dark;}
  *, .p-button, .p-button:hover {background-color:#08A350 !important;}
  // .p-button.p-button-secondary, .p-button.p-button-secondary:hover {background-color: $bg-color}
}
.pager-container {
  padding-bottom: 70px;
}
.containers-fluid{
  margin-left: 40px;
}

.news-section {
    
    .news-header {

        margin-bottom: 25px;

        @media #{$min-med} {
            margin-bottom: 50px;
        }

        h2 {
            color: rgba(0, 0, 0, 0.7);
            margin-inline-start: 10px;
            text-transform: capitalize;
            font-family: sans-serif;
           // margin-top: -100px;

            @media #{$min-med} {
                margin-inline-start: 25px;
            }
        }
        .view-all {
            color: $main-color;
            font-size: clamp(12px, 1.5vw, 14px);
            text-transform: uppercase;
          //  margin-top: -100px;
        }
    }
        
}

.news-details-page,
.news-section {
    .cards-wrapper {
       // height: 300px;
        .card-col:last-child {
            @media #{$max-med} {display: none}

        }
        .card-col:first-child {
            @media #{$max-med} {display: block}
        }
        .card-col:nth-child(2) {
            @media #{$max-med} {display: block}
        }
        
    }
}

.news-section .card,
.news-details-page .card,
.news-page .card {

    width: 100% !important;
    border-radius: 10px;
    box-shadow: 0px 8px 25px rgba(211, 211, 211, 0.25);
    img {height: 150px; 
        border-radius: 10px 10px 0 0;}
        height: 550px;

    @media #{$min-small} {
        width: 90% !important;
        img {
            height:250px;
        }
    }
    @media #{$max-med} {
        margin-bottom: 15px !important;
    }
    @media #{$min-med} {
        img {
            height: 300px;
        }
    }

    .card-details {
        display: flex;
        //justify-content: space-between;
        margin-bottom: 10px;
       

        .card-title {
            font-size: clamp(14px, 1.9vw, 18px);
            font-weight: 600;
            width: 100%;
           // height: 300px;
            text-size-adjust: 10px;
            color: $main-color;
            overflow: hidden;
           // white-space: nowrap;
            margin-inline-end: 10px;
            max-width: 70%;
            margin-top: 10px;
            float: inline-start;
        }
       
        .card-description{
            margin-bottom: 20px;
        }
        span {
            color: #AA5656;
            font: normal normal normal 1em/1 'LineIcons';
            margin-top: 20px;
            font-size: 13px;
            
        }
        .btn{
            color: #212529;
        }

    }

    span, p {
        font-size: clamp(12px, 1.5vw, 14px);
    }

    .card-text {
        color: #212529;
        height: 40px;
        overflow: hidden;
        margin-bottom: 10px;
        @media #{$min-small} {
            height: 55px;
        }
        @media #{$min-med} {
            height: 40px;
        }
        
    }
    .card{
        height: 300px;
    }
    
}
.testimonials-section {
   // background-image: url(/assets/media/testimonial.png);
   background-color: #fff;
    background-size:cover;
    .head-wrapper {
        @media #{$min-large} {padding-inline-start: 110px}
        margin-bottom: 40px;
        h2 {color: $text-dark;
       // text-align: center;
        text-transform: capitalize;
       //font-size: 50px; 
       // margin-left: -100px;
        //margin-top: 100px;
        padding-top: 7%;
       }
        
    }
    .caro-container {

        @media #{$min-large} {padding-inline-start: 100px}
        height: 100%;
        .slick-slider {          
            .testi-box {
                margin: auto;
                width: 100% !important;
                min-height: 230px;
                * {
                    font-size: $sm-size;
                }

                span {
                    color: #AA5656;
                    display: inline-block;
                    margin-bottom: 10px;
                    text-decoration:wavy;
                   // margin-top: 50px;
                    text-transform: capitalize;
                    margin-left: 20%;
                }
                
                h4 {
                    margin-bottom: 5px;
                    margin-left:20%
                }
                h3{
                   // margin-top: 7%;
                   font-size: larger;
                    margin: 7%;
                    font-family: sans-serif;
                    margin-left: 20%;
                }
                img {
                    // position: absolute;
                     width: 30%;
                     border: 1px bold #AA5656;
                     top: 29px;
                     float: left;
                    position: relative;
                   // margin-left: 43%;
                     opacity: 0.8;
                     color:#e30d0d;
                     @media #{$min-med} {
                         
                         width: 15%;
                     }
                    
                 }

            }

            margin-bottom: 40px;

            .slick-next{
                @media #{$max-small} {display: none !important;}
                &:before {
                    color:#AA5656;
                     font-size: 105px;
                     font-weight: 1000;
                    // padding-right: 6%;
                    margin-left: -100%;
                }
            }
           
            .slick-next:before {
                font-size: 50px;
                z-index: 1;
            }
            .slick-prev {
               display: none !important;
               border-radius: 50%;
               width: 55px;
               height: 55px;
               display: flex;
               align-items: center;
               justify-content: center;
               background-color: $main-color;
               color: rgba(0, 0, 0, 0.9);
               border: 1px solid #294B29;
               transition: all 0.3s ease-out 0s;
               margin: 20px 0px 0 600px;

            }
            

        }

    }
}
.hero-section {

    display: flex;
    align-items: center;
   
   height: 300px;
    animation-name: zoomBG;
    animation-duration: 20s;
    animation-timing-function: ease-in;
     animation-iteration-count: infinite;
     @keyframes zoomBG {
            0% {
                background-size: 100% 100%;
            }
        
            100% {
                background-size: 140% 140%;
            }
        }

    h1 {
        font-size: 20px;
        color: #fff;
        font-family: sans-serif;
       
    }
    
    @media #{$min-small} {
        height: 250px;
        h1 {
            font-size: 25px;
        }
    }
    @media #{$min-med} {
        height: 450px;
        h1 {
            font-size: 30px;
        }
    }
    @media #{$min-large} {
        height: 700px;
        h1 {
            font-size: 55px;
            font-family: Inter, system-ui; 
            width: 50%;
            margin: auto;
            //color: #294B29;
        }
    }
    @media #{$min-Xlarge} {
        h1 {
            font-size: 55px;
        }
    }
  
    // .search-set {
    //     width: 30%;
    //     position:relative;
    // //  padding: px;
    // font-size: 17px;
    // // display: flex;
    // left: 60px;
    // top: -200px;
    // // border: 1px solid grey;;
    // float: left;
    // background-color:#0005;
    //    }
     
}

.chat-page {

    .content-wrapper, .row {
        height: inherit;
        display:-moz-inline-box;
    }

    .chat-sidebar {

        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        * {color: $text-dark}

        h4 {
            font-size: $sm-size;
        }

        .search-set {
            width: 100%;
            position: unset;
            margin-top: 10px;
            margin-right: 20px;
        }

        .conversations-list {

            flex: 1 1;
            overflow: auto;
            max-height: 56vh;
            @media #{$min-large} {
                max-height: 58vh;
            }
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }

            ul, li {
                padding: 0 !important;
                margin-bottom: 0 !important;
            }
             
            li {
                button {
                    width: 100%;
                    text-align: start;
                    margin-bottom: 0 !important;
                    padding: 20px;
                    border-bottom: 1px solid #dee2e6;
                    height: 100px;
                    transition: all .2s ease-in-out;
                    border-inline-start: 3px solid transparent;
                    font-size: $sm-size;

                    &:hover,
                    &.active {
                        background-color: #f4fef9;
                        border-inline-start-color: $main-color;
                    
                    }

                    p {
                        font-size: $xs-size;
                        color: $text-light;
                        overflow: hidden;
                        pointer-events: none;
                        text-transform: none;
                        white-space: nowrap;
                    }

                }
            }
        }

        .group-message {
            border: 1px solid $main-color;
            border-radius: 4px;
            color: $main-color;
            padding: 10px;
            font-size: $sm-size;
        }

    }

    .chat-msg {

        display: flex;
        flex-direction: column;
        @media (max-width: 2000px) {justify-content: space-between;}

        .back-button {
            font-size: $sm-size;
            justify-content: flex-start;
            margin-bottom: 10px;
            color: $text-light;
        }

        h4 {
            font-size: $md-size;
        }

        .msg-area {
            
            flex: 1 1;
            overflow: auto;
            max-height: 56vh;
            @media #{$min-large} {
                max-height: 60vh;
            }
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }

            .msg-container {

                width: fit-content;
                &.set-cont {margin-inline-start: auto;}

                .self-msg, .other-msg {
                    padding: 15px;
                    text-transform: none;
                    font-size: $sm-size;
                    border-radius: 4px;
                    word-break: break-word;
                    min-width: 150px;
                }

                .self-msg {
                    background-color: #eaeff4;
                    color: #51606d;
                    margin-inline-start: auto;
                    .file-data {
                        color: $text-dark;
                    }
                }

                .self-details, .other-details {color: $text-light; font-size: $xs-size; margin-bottom: 0 !important; justify-content: space-between;}
                .self-details {justify-content: end}

                .other-msg {
                    background-color: #28a745!important;
                    color: #fff !important;
                }

            }

            
            .load-more {
                background-image: linear-gradient(to top, rgb(8 163 80 / 5%) -98%, rgba(74, 144, 226, 0));
                transform: rotate(180deg);
            }

        }

        input {
            border-radius: 0;
            border: 0;
            border-top: 1px solid #dee2e6
        }

        button i {
            color: $text-light;
            font-size: 16px;
            padding: 15px 5px;
        }
    }

    .chat-sidebar, .chat-msg {
        background-color: #fff;
    }

    .conversations-list ul,
    .chat-msg .msg-area ul {
        overflow: auto;
        // height: 25em;
        // &::-webkit-scrollbar {
        //     display: none;
        // }
        // scrollbar-width: none; 
    }
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-inline-start: .5rem;
}
.p-checkbox.p-component {
    margin-inline-end: .5rem;
}
.contact-page {
      
    .definition {
        @media(min-width: 1400px)
        {
            adding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    float: inherit;
        p {
            width: 85%;
            @media #{$min-large} {width: 65%}
        }
    }
    }

    .contact-lower {

       // margin-top: 40px;
        max-width: 1720px;
        overflow: hidden;
        .contect img{
            width: 500px
           // margin-top: 20%;
        // padding-top: 20%;
           // height: 70%;
        }
        .hover-img-container {
            overflow: hidden;
            position:relative;
            width: fit-content;
            transition: all .3s;
            //margin-left: 1%;
           // padding: 20%;
            //height: 50%;
        }
        .hover-img-container::before {
            content: '';
            position: absolute;
            bottom: -10%;
            right: -10%;
            width: 25%;
            height: 25%;
            background-color: transparent;
            border-bottom: 6px solid #50623A;
            border-right: 6px solid #50623A;
            transition: all .7s ease;
            z-index: 2;
        }
        
        .hover-img-container::after {
            content: '';
            position: absolute;
            top: -10%;
            left: -10%;
            width: 25%;
            height: 25%;
            background-color: transparent;
            border-top: 6px solid #50623A;
            border-left: 6px solid #50623A;
            transition: all .7s ease;
            z-index: 2;
        }
        
        .client-hover-img-container {
            overflow: hidden;
           position: relative;
            width: 100%;
        }
        
        .hover-img-container img,
        .client-hover-img-container img {
            transition: all 2s;
            position: relative;
            display: inline-block;
        }
        
        .hover-img-container:hover img,
        .client-hover-img-container:hover img {
            transform: scale3d(1.2, 1.2, 1.2);
            z-index: 1;
        }
        
        .hover-img-container:hover::after {
            top: 20px;
            left: 20px;
        }
        
        .hover-img-container:hover::before {
            bottom: 20px;
            right: 20px;
        
        }
        .about-one-img-small {
        
            top: 100px;
           
        }
        .about-one-img{
        top: 2px;
        }

        @media #{$min-large} {margin-top: 70px;}
        .contact-box ul{
           //position: relative;
           list-style-type: none;
           //margin: 50px;

           
           overflow: hidden;
           li{
           text-align:center;
            display: inline;
            float: left;
            flex: 0 0 auto;
            width: 30%;
           // position:absolute;
            margin-left: 20px;
           }
           li h3{
            display: block;
           // text-align: center;
             padding: 20px 0px 14px ;
             text-decoration: none;
             font-family: sans-serif;
           }
           img {
            height: 5%;
            width: 30%;
            text-align: center;
           }
           span{
            padding-bottom: 30px;
           }
           span a{
            color: #AA5656 ;
           }
        }

        .custom-error-msg {
            text-align: start;
        }
        .HQ {
            @media #{$max-small} {order: -1;}
            .contact-box {
                padding: 30px;
                
            }
        }

        .contact-submit {
            @media #{$min-med} {text-align: start;}
        }
    }

}
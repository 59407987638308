.ours-section {

    .col {
      //  padding-left: 30px;
        padding-right: 30px;
    }

    h2.head-style {
        color: $text-dark;
        text-align:left;
        margin-bottom: 20px;
        @media #{$min-med} {margin-bottom: 40px;}
    }

    .ours-card {
        border: solid 1.3px #e2e2e2;
        border-radius: 20px;
        padding: 45px;
        background-color: #fff;

        p {
            font-size: $sm-size;
        }

        img {
            margin-bottom: 25px;
        }

        .max-text {
            max-height: 90px;
        }
        
        h3 {
            font-size: $md-size;
            margin-bottom: 15px;
            @media #{$min-med} {margin-bottom: 25px;}
        }

        @media #{$max-med} {
            img {
                width: 80px;
                height: 70px;
            }
            padding: 50px;
        }
        
        @media #{$max-small} {
            img {
                width: 70px;
                height: 60px;
            }
            padding: 25px;
        }
        
        p {
            color: #888888;
            
        }
    }
}
html:lang(ar) {
    
    body, h1, h2, h3, h4 {
        font-family: Inter;
    }

    .fa-arrow-left, .pi-angle-right, .p-paginator-last, .p-paginator-prev, .p-paginator-first {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    button, .button-lang a {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

    .rot-180 {
        transform: rotate(180deg);
    }

    .content-upper .centric {
        align-items: flex-start;
    }

    .p-dropdown-clear-icon {
        right: 2.357rem !important;
        left: unset !important;
    }

    .chairs-section .caro-bg {
        right: unset;
        left: 0;
    }

    .chairs-section .carousel-demo .card .product-item .product-item-content .product-item-wrapper .product-item-details {
        right: 0;
    }

    .carousel-control-prev-icon {
        border-inline-start: 10px solid $text-dark; 
        border-inline-end: unset !important; 
    }
    .carousel-control-next-icon {
        border-inline-end: 10px solid $text-dark; 
        border-inline-start: unset !important; 
    }

    .p-carousel-indicators {
        direction: rtl;
        text-transform: lowercase;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        float: right;
        padding-right: unset;
        padding-left: .5rem;
    }

    .sidebar ul li a.active {
        background-image: linear-gradient(to left, #08a35033 -98%, rgba(74, 144, 226, 0));
    }

    .search-set {
        @media #{$min-large} {
            left: unset;
            right: 260px;
        }
    }

    .testimonials-section .caro-container .slick-slider .testi-box {
        text-align: end;
    }

    .news-section .card .card-details .card-title,
    
    .news-section .card .card-details .card-title,
    .news-details-page .card .card-details .card-title,
    .news-page .card .card-details .card-title,

    .category-page .cards-wrapper .card h3,
    .max-text {
        padding-top: 15px;
        position: relative;
        top: -10px;
    }
    
    .login-page .login-form .form-title h2 {
       // font-family: Ooredoo;
       font-family: Noto_Sans;
    }

    .countries-list.p-dropdown .p-dropdown-clear-icon {
        right: unset !important;
        left: 2.357rem !important
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
        margin: 0;
        padding-inline-start: .8rem;
        margin-inline-end: -1.5rem;
        padding-inline-end: 1.7rem;
    }    
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
        left: 0.5rem;
        right: unset !important;
    }
}
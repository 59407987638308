@font-face {
  font-family: RobotoRegular;
  src: url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RobotoMedium;
  src: url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: RobotoBold;
  src: url('../../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  //font-family: Ooredoo;
  font-family: Noto_Sans;
  src: url('../../fonts/Noto_Sans/NotoSans-VariableFont_wdth,wght.ttf') ;
}
  
body {
  font-family: RobotoRegular;
  //text-transform: lowercase;
}

h1, h2, h3, h4 {
  font-family: RobotoBold;
}
.admin-SB {

    background-color: #fff;
    color: $text-dark;
    position: sticky;
    top: 0;
    flex: 1 1;
    border-top: 2px solid $bg-color;
  
    .sidebar {
  
      height: calc(100% - 2px);
      position: fixed;
      width: 100%;
      padding: 0;

      ul {
    
        margin-bottom: 0;
        
        li {  
    
          &.logout {
           position: absolute;
            bottom: 13%;
          }
    
          a {
            width: 100% !important;
            display: inline-block;
            font-weight: bold;
            padding: 10px 20px;
            margin-inline-start: -31px;
            &.active {
                color: $main-color;
                background-image: linear-gradient(to right, #08a35033 -98%, rgba(74, 144, 226, 0));                border-inline-start: 2px solid #c6c5fa;
                border-inline-start: 3px solid #08a35063;
            }
          }
    
          a {
            display: inline-block;
            color: $text-dark;
            font-size: 16px;
          }
        }
      }
    }
    
  }

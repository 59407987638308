.dashboard-container {
    left: 50%;
    transform: translateX(-50%);

    .p-toast {
        z-index: 1102 !important;
    }

    .dashboard-section {

        padding-bottom: 55px;
        &.withPager {padding-bottom: 80px;}

        h3, h2, label {color: $text-dark}
        label {font-size: $sm-size; margin-bottom: 12px;}
        button.main-button {max-width: 250px;text-transform: capitalize;&:hover {background-color: $main-color !important;border-color: $main-color !important;}}
        .content-upper {
            justify-content: space-between;
            align-items: center;
            h2 {font-size: $lg-size;}
        }

        .p-tabview .p-tabview-panels {display: none !important;}
        .p-tabview .p-tabview-nav {
            border: 0 !important;
            
            li {
                border-radius: 0.2rem !important;
                margin-inline-end: 20px;
                overflow: hidden;
                text-align: center;
                border: 1px solid #222222;
                a {
                    background-color: transparent;
                    min-height: 35px;
                    outline: 0;
                    border: $main-color;
                    font-size: $sm-size;
                    font-weight: 500;
                    display: block;
                    padding: 10px;
                    min-width: 70px;

                    @media #{$min-med} {
                        width: 160px;
                    }
    
                }
            }
            &, li .p-tabview-nav-link {
                background-color: transparent;
            }
            li.p-highlight {

                .p-tabview-nav-link {
                    background-color: $main-color;
                    color: white !important;
                }
                border: 0;
            }
            li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {background-color: transparent;}
        }

        .content-language {
            flex-wrap: wrap;
        }
        .content-border {
            border-color: #979797;
        }
        h3.content-head {
            margin-inline-end: 30px;
            min-width: 50px;
            font-size: $md-size;
            @media (max-width: 449px) {margin-bottom: 15px;}
            @media #{$min-small} {min-width: 70px}
            @media #{$min-med} {min-width: 90px;}
        }

        .rsrc-list .card-holder {
            display: flex;
            flex-direction: column;
        }

        &.links-section {
            .cards-wrapper .card {
    
                color: #fff;
                color: $text-dark;
                font-weight: 800;
                font-size: clamp(14px, 1.3vw, 17px);
                background: transparent;
                border: 0;
                cursor: pointer;
    
                &:hover {
                    color: $main-color;
                }
            }
        }

        .users-page {
            .p-datatable .p-datatable-thead > tr > th {
                background-color: #f5f6f9;
            }
        }
        .news-page {
            .card {
                width: 100% !important;
    
                @media #{$min-med} {
                    img {
                        height: 250px !important;
                    }
                }
            }
            .news-options {
                border-top: 1px solid #ebebeb;
                padding: 10px;
                button {
                    margin-bottom: 0;
                    &:first-of-type {
                        border-inline-end: 1px solid #ebebeb;
                        padding-inline-end: 10px !important;
                    }
                }
            }
        }
        .users-page, .news-page {
            margin-bottom: 90px;
        }


        .content-form .home-form {
            section {
                margin-bottom: 30px;
                @media #{$min-med} {margin-bottom: 45px;}
                .input-style.upload {
                    height: 130px;
                }

                .form-array .push-button {
                    border: dashed 1px #999696;
                    font-size: $sm-size;
                    color: $text-light;
                    width: 100%;
                    height: 94%;
                    min-height: 180px;
                    @media #{$min-small} {min-height: 253px;}
                }
                .form-array.testi-array .push-button {
                    height: 90%;
                    min-height: 180px;
                    @media #{$min-small} {min-height: 190px;} 
                }
                .p-fileupload-content {
                    height: 130px;
                }
            }
        }


    }
    
}

.users-dialog.p-dropdown .p-dropdown-label.p-placeholder,
.users-dialog.p-multiselect .p-multiselect-label.p-placeholder {
    font-size: $xs-size;
    color: #83a7a5;
}

.countries-list.p-dropdown .p-dropdown-clear-icon {
    left: unset !important;
    right: 2.357rem !important
}
.msg-section {

   .start-part {
    

       .start-part-wrapper {

            text-align: end;
            align-items: center;
            display: flex;
            padding-right: 15px;
            padding-left: 15px;
           .hover-img-container {
            overflow: hidden;
            position:relative;
            width: fit-content;
            transition: all .3s;
            //margin-left: 1%;
            padding: 3%;
            //height: 50%;

       }
        .hover-img-container::before {
            content: '';
            position: absolute;
            bottom: -10%;
            right: -10%;
            width: 25%;
            height: 25%;
            background-color: transparent;
            border-bottom: 6px solid #50623A;
            border-right: 6px solid #50623A;
            transition: all .7s ease;
            z-index: 2;
        }
        
        .hover-img-container::after {
            content: '';
            position: absolute;
            top: -10%;
            left: -10%;
            width: 25%;
            height: 25%;
            background-color: transparent;
            border-top: 6px solid #50623A;
            border-left: 6px solid #50623A;
            transition: all .7s ease;
            z-index: 2;
        }
        
        .client-hover-img-container {
            overflow: hidden;
           position: relative;
            width: 100%;
        }
        
        .hover-img-container img,
        .client-hover-img-container img {
            transition: all 2s;
            position: relative;
            display: inline-block;
        }
        
        .hover-img-container:hover img,
        .client-hover-img-container:hover img {
            transform: scale3d(1.2, 1.2, 1.2);
            z-index: 1;
        }
        
        .hover-img-container:hover::after {
            top: 20px;
            left: 20px;
        }
        
        .hover-img-container:hover::before {
            bottom: 20px;
            right: 20px;
        
        }
        .about-one-img-small {
        
            top: 100px;
           
        }
        .about-one-img{
        top: 2px;
        }

            img {
               width: 85%; 

            }
            .about-one-imge {
                width: 50%;

            }


            .start-imgs {
                padding-inline-end: 25px;
                
                .top_image {
                    
                    @media #{$min-small} {
                       
                        width: 85%
                    }
                }
                .side_image{
                    width: 85%;
                   
                    margin-top: 30%;

                }
                .bottom_image {
                   
                   left: 30%;
                    @media #{$min-small} {
                       height: 50%;
                        //width: 100%
                       // width: 85%
                    }
                   // margin-top: 25px;
                width: 85%;
                    
                }
            }

       }

       .side-img-container {
            img {
                //height: 100%;
                @media #{$min-small} {
                    
                    width: 90%;
                }
            }
       }

   }

   .end-part {

        @media #{$max-med} {
         width: 85%;
            margin-top: 50px;
            margin-right: 50px;
        }

    }

    .end-part, 
    .side-img-container , 
    .start-part-wrapper {
        display:flexbox;
         align-items: center;
         
    
    }
    
}

.msg-section .content-wrapper,
.map-section .content-wrapper,
.chairs-section .content-wrapper {

   // justify-content: flex-start;

    h2 {
        width: 100%;
        display: block;
       // text-align: center;
        font-size: $xl-size;
        color: $text-dark;
        span {color: $main-color; margin-inline-start: 10px;}
       margin-top: 10%;
        margin-bottom: 50px;
       // margin-left: -180px;
    }

    .details-wrapper {

        color: $text-light;
       // margin-top: 20px;
      //  margin-left: -100px;

        a {
            color: $main-color;
            display: inline-block;
        }

        p {
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            @media #{$min-small} {
                padding-inline-end: 10px;
            }
            @media #{$min-large} {
                width: 85%;
            }
        }

        p, a {
            font-size: $sm-size;
           // display: table-header-group;
        }

    }

}

.map-section {
    p {
        line-height: 26px;
    }
    .img-cont {
        @media #{$max-med} {
            text-align: center;
        }
        img {
            //height: 700px;
            @media #{$max-small} {
                margin-top: 20px;
            }
            @media #{$min-med} {
                width: 80%;
               // height: 700px;
            }
        }
    }
    .details-wrapper .max-text {
        max-height: 750px;
    }
    .header-option {
        @media #{$max-med} {
            order: -1;
            margin-bottom: 35px;
    

        }
        a {
            border: 0px solid $main-color;
            box-sizing: border-box;
            color: #fff !important;
            background-color: $main-color;
           // padding: 5px;
            //margin-inline-start: 20px;
           // margin-inline-end: 0;
            border-radius: 40px;
            //display: flex;
           // padding: 0.5rem 18px;
            font-size: 14px;
            font-weight: 600;
        }
        a:hover {
            background-color:#50623A ;
            color: white;
          }
    }

}

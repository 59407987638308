
.news-details-page {
   
    .details-container {
        margin-top: 15px;
        color: #3a3a3a;
        @media #{$min-med} {
            margin-top: 25px;
        }
      
        .breadcrumb {
            background-color: unset;
            padding: 0;
            padding-bottom: 10px;
            * {color: $text-dark;}

            .breadcrumb-item {
                font-size: clamp(12px, 1.8vw, 16px);
                & {padding-inline-start: 0; padding-inline-end: 10px;}
                &.active {color: $text-light}
            }
        }

        h2 {
            font-size: clamp(18px, 2.5vw, 22px);
            font-weight: 600;
        }
        
        img {
            max-height: 450px;
        }

        p {
            font-size: clamp(12px, 1.8vw, 16px);
        }

        span {
            i {margin-inline-end: 7px}  
            font-size: clamp(12px, 1.5vw, 14px);
            color: #AA5656;
        }
        
        .head-wrapper {
            h3 {
                font-size: clamp(12px, 1.8vw, 16px);
                border-bottom: 2px solid #eeeeee;
                padding-bottom: 10px;
                position: relative;
                &::before {
                    content: '';
                    width: 40px;
                    height: 2px;
                    bottom: -2.5px;
                    position: absolute;
                    background-color: $main-color;
                }
            }
        }

    }

}
// .hero-section{
//     height: 300px;
//     display: flex;
//     align-items: center;
//    animation-iteration-count:inherit;
//    background-color:#eeeeee;
//     @media #{$min-large} {
//         height: 500px;
//         h1 {
//             font-size: 55px;
//             font-family:sans-serif; 
//             text-transform: capitalize;
//             width: 50%;
//             margin: auto;
//             color: #294B29;
//             //color: #294B29;
//         }
//     }
// }
.heroo{
    width: 100%;
  // height: 300px;
   background-position: center;
   h1{
    color: #294B29;
   }
     @media #{$min-large} {
        height: 600px;
        h1 {
            font-family: Inter, system-ui; 
            color: #294B29;
        }
    }
}
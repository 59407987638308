.login-page {

    margin-bottom: -70px;

    .bg-size {min-height: 700px;}

    .login-form {

        width: 70%;
        right: 0;
        margin: 20px;
        border-radius: 15px;
        
        padding: 5%;
        background-color: white;

        @media #{$min-med} {
            width: 500px;
        }

        .form-title  {

            margin-bottom: 35px;

            @media #{$min-small} {
                margin-bottom: 50px;
            }

            h2 {
                font-family: RobotoRegular;
                font-weight: 70;
                font-size: clamp(18px, 3vw, 36px);
                color: #294B29;
                border-width: 2px;
                padding-bottom: 8px;
            }
            .btn:hover {
                opacity: 0.8;
              }
              
        }

    }

}

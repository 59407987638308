footer {

   // padding-top: 20px;
   // padding-bottom: 20px;

    background-color: $part-color;
    color: $bg-color;

    @media #{$min-med} {
        padding-top: 30px;
        padding-bottom: 20px;
    }
   .col-66{
    // width: 10%;
     margin-left: .5%;
     nav{
        width: 20%;
     }
   }
   .col-6,.col-12 {
  padding-right: 15%;
   }
   .col-65{
    width: 60%;
   // position:absolute;
    float: left;
   // margin-left: 10%;
   @media #{$min-med} {
    width: 25%;
}
   
   @media #{max-med}{
    width: 100%;
   }
}
  
    // p {
    //    // text-align: center;
    //     font-size: clamp(12px, 1.7vw, 16px);
    // }

    // h3 {
    //     font-size: clamp(13px, 1.8vw, 17px);
    // }

    li, small, i {
        font-size: clamp(10px, 1.5vw, 15px);
    }

    // a {
    //     font-size: clamp(10px, 1.5vw, 13px);
    // }

    // a {text-transform: uppercase;}

    .core {
        margin-top: 10px;
       // margin-bottom: 30px;
       margin-bottom: -2%;
       width: 100%;
        @media #{$min-med} {
            // margin-top: 60px;
            // margin-bottom: 60px;
        }
       

        ul {
            float: right;

            padding: 0;
            li {
                margin-top: 5px;
               // margin-bottom: 5px;
            }
            overflow: hidden;
            word-break: break-word;
        }
    }

    hr {
        background-color: #4a4a4a;

        margin-top: 20px;
       // margin-bottom: 20px;

        @media #{$min-med} {
            margin-top: 30px;
           // margin-bottom: 30px;
        }
    }
    .soc-links {
        i {
            margin-inline-start: 18px;
        }
    }
}


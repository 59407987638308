.about-page, .contact-page {
   // text-align: left;
    position:relative;
    
    
    h2 {
        color: #3A3A3A;
        padding-bottom: 5px;
        @media #{$min-small} {padding-bottom: 7px}
        @media #{$min-med} {padding-bottom: 10px}
        text-transform:capitalize;
        padding-left: 5%;
        padding-top: 5%;
        


        &::after {
            content: "";
            width:auto;
            height: 1.7px;
            position: absolute;
            background-color: #789461;
            left: 10%;
            transform: translate(-50%, -50%);
            margin-top: 30px;
            
            @media #{$min-med} {margin-top: 40px;}
            @media #{$min-large} {margin-top: 50px;}

        }
    }
    p {
        color: #3A3A3A;
        ;
        font-size: clamp(12px, 1.5vw, 16px);
       // width: 85%;
     //  text-align: left;
       
    }

    .africa {
        h2{
            padding-right: 5% ;
        }
        p {
            color: #3A3A3A;
            ;
            font-size: clamp(12px, 1.5vw, 16px);
           // width: 85%;
          // text-align: left;
           padding: 0% 5% 0% 5%;
        }

    }
    .history{
        h2{
            padding-right: 5% ;
        }
        p{
            padding: 0 5% 0% 5% ;
            color: #3A3A3A;
        }
    }
    .objective{
        h2{
            padding-right: 5% ;
        }
        padding-top: 5%;
       .service-one{
        top: 0;
        left: 0;
        padding: 3% 5% 3% 5%;
        width: 100%;
      //  height: 30%;
        overflow: hidden;
        position:relative;
        display:ruby-text;
       
    
        }
        .service-one-contents{
        width: 30%;
        height: 200px;
       // padding: 10px;
       
       
        }
        .hover-container {
        overflow: hidden;
        position:relative;
        background-color: #DBE7C9;
       // width: fit-content;
        transition: all .3s;
        //margin-left: 1%;
        padding: 3%;
        //height: 50%;
        display:grid;
        margin-left: 2%;
       box-shadow: #3A3A3A;
       // margin: inherit;

       }
        .hover-container::before {
        content: '';
        position: absolute;
        bottom: -10%;
        right: -10%;
        width: 25%;
        height: 25%;
        background-color: transparent;
        border-bottom: 3px solid #50623A;
        border-right: 3px solid #50623A;
        transition: all .7s ease;
        z-index: 2;
    }
    
    .hover-container::after {
        content: '';
        position: absolute;
        top: -10%;
        left: -10%;
        width: 25%;
        height: 25%;
        background-color: transparent;
        border-top: 3px solid #50623A;
        border-left: 3px solid #50623A;
        transition: all .7s ease;
        z-index: 2;
    }
    
    .client-hover-container {
       // overflow: hidden;
       position: relative;
        width: 100%;
    }
    
    .hover-container:hover::after {
        top: 20px;
        left: 20px;
    }
    
    .hover-container:hover::before {
        bottom: 20px;
        right: 20px;
    
    }
}
.organs{
    h2{
        padding-right: 5% ;
    }
    ul{
        border: 6px bold #f02207;
        padding: 3% 5% 5% 5%;
        list-style: circle inside;

        }
      il{
        border: 6px bold #f02207;
        padding: 0 5% 5% 5%;
        list-style: circle outside;
        width: 20%;
        height: 20%;
        h3{
            color: #3A3A3A;
        padding-bottom: 5px;
        @media #{$min-small} {padding-bottom: 7px}
        @media #{$min-med} {padding-bottom: 10px}
        text-transform:capitalize;
        }
        p{
            padding: 2% 0 0 2%;
        }
         }
}
.members{
    h2{
        padding-right: 5% ;
        padding-bottom: 5%;
    }
    .flags{


        il{
            position: relative;
           // padding-top: 5%;
            display:inline-grid;
            

         }
        img{
            padding: 2%;
            width: 13%;

        }
    }
}


}
.links-page {
    .cards-wrapper {
        .card {
            border-radius: 4px;
            background-color: transparent;
            transition: all .5s ease-in-out;
            min-height: 100px;
            border: 0;

            a {
                color: $main-color;
                font-weight: 800;
                height: 100%;
                width: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                text-align: center;
                font-size: clamp(14px, 1.3vw, 17px);
                justify-content: center;
                overflow: hidden;
                align-items: flex-start;
            }
            
            &:hover {
                background-color: #333333;
                a {
                    color: #fff;
                }
            }
            
            
        }
    }
}
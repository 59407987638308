// .chairs-section {
//    // display:-webkit-box;
//     background-color: #ffffff;
//     .col-lg-3{
//         float:left;
//         margin-left: 20%;
//         margin-top: 5%;
//         width: 25%;
//         clear: none;
//         h2{
//             text-align: center;

//         }
//          flex: 0 0 60%; 
//          max-width: 100%; 
//     }
   

//     .col-lg-9{
//         width: 100%;
//         float: left;
//         margin-left: 7%;
//     }
//     .container-fluid {
//         .content-wrapper {
//             width: 100%;
//             height: 100%;
//             float: left;
//             //margin-left: 240px;
//             * {color: rgba(0, 0, 0, 0.7);
//                 //margin-bottom: 20px;
//                 font-size: 30px;
//                 //margin-left: 250px;
//             }
//             display: flex;
//             align-items: center;
//             @media #{$max-med} {
//                 margin-top: 50px;
//                 margin-left: 20px;
//             }
//             p {width: 100%;
//             margin-top: -30px;
//            // margin-left: 100ox;
//             text-align: center;
//            // margin-right: 300px;
//            float: left;
//         }
//         }
//     }

//     .carousel-demo {

//         .card {

//             border: 2px;
//             background: transparent;
//             width: 100%;
//             margin-left: 7%;

//             .product-item {

//                 .product-item-content {

//                     border-radius: 3px;
//                     text-align: center;
//                    padding: 2rem 0;
//                    margin-top: -100px;
//                     //max-width: 900px;
//                    box-sizing: content-box;
//                   //margin-left: 10px;
//                    float: right;
//                    width: 90%;
//                    display: inline;
                   
//                     .product-item-wrapper {
//                         width: 86%;
//                        // max-height: 400px;
//                        height: auto;
//                         margin: 20px;
//                         position: relative;
                       
//                         border-radius: 10px;
//                         border: 1px solid #ccc;
//                       overflow: hidden;
//                     //     margin-top: 30px; 
//                     //    // margin-left: ;
//                     //    // -webkit-transition: all .5s cubic-bezier(.68,-.55,.27,1.55) 0s;
//                     //     //transition: all .5s cubic-bezier(.68,-.55,.27,1.55) 0s;
//                     //     visibility: visible;
//                     }
   
//                         .product-item-details {

//                             // min-width: 100%;
//                              height: 70px;
//                              background-color: #ffffff;
//                             position:fixed;
//                             bottom: -10px;
//                              color: #294B29;
//                              margin-left: 6%;
//                             margin-bottom: 40px;
//                             //border-radius: ;
//                             margin-top: 50px; 
//                            visibility: visible;
//                            //padding: 20px 20px 20px 20px;
//                             min-width: 210px;
//                             text-align: center;
//                             box-shadow: 0px 8px 25px rgba(211, 211, 211, 0.25);
//                             padding: 15px 0;
//                             //transform: skewX(-10deg);
//                             width: 20%;
//                            //width: calc(60% - 60px);
//                              border-radius: 3px;
//                             //margin: auto;
//                             // transition: all 0.3s ease-out 0s;
//                             // position: relative;
//                             // z-index: 1;
                        
                                                      
//                             @media #{$max-med} {
//                                 padding: 16px;
//                             }

//                             @media #{$max-small} {
//                                 padding: 15px;
//                             }

//                             h5 {
//                                 margin-top: 5px;
//                                 color: #bfbfbf;
//                             }

//                             h4, h5 {
//                                 @media #{$max-med} {
//                                     font-size: 15px;
//                                 }
//                                 @media #{$max-small} {
//                                     font-size: 14px;
//                                 }
//                                 @media #{$max-mobile} {
//                                     font-size: 12px;
//                                 }
//                             }

//                         }

//                         .product-image {
//                             width: 100%;
//                             height: 300px;
//                             border-inline-start: 1px solid $part-color;
//                         }
//                 }
//             }
       
//             .product-item:hover{
//                 border: 1px solid #fff;
//                 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.0), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//             }

//             .p-carousel {
//                 z-index: 1;
//             }

//             .p-carousel-items-container {

//                 margin-top: 70px;

//                 @media #{$min-small} {
//                     margin-top: 120px !important;
//                 }

//             }

//             .p-carousel-next,
//             .p-carousel-prev {
//                 display: none;
//             }

//         }

//     }

//     .caro-bg {
//         position: absolute;
//         width: 90%;
//         right: 0;
//         height: 100%;
//         background-color: $part-color;
        
//         h2 {
//             padding-top: 40px;
//             @media #{$max-small} {
//                 margin-inline-start: 40px;
//             }
//         }

//         @media #{$min-med} {
//             width: 85%;
//             h2 {
//                 padding-inline-start: 60px;
//                 padding-top: 60px;
//             }
//         }

//         @media #{$min-large} {
//             h2 {
//                 margin-inline-start: 35px;
//             }
//         }
        
        
//     }

// }

// .carousel-demo .card .p-carousel-indicators {

//     .p-carousel-indicator {
//         margin-inline-end: .5rem;
//         border: 1px solid transparent;
//         padding: 3px;

//         button {
//             width: 8px;
//             height: 8px;
//             border-radius: 50%;
//         }
//         &.p-highlight {
//             border: 1px solid $main-color;
//             border-radius: 50%;

//             button {
//                 background-color: $main-color;
//             }
//         }

//     }

//     @media #{$min-med} {

//         .p-carousel-indicator button {
//             width: 10px;
//             height: 10px;
//         }
        
//     }

//     @media #{$min-large} {

//         padding-bottom: 40px;

//         .p-carousel-indicator {

//             padding: 4px;

//         }

//     }
  

// }
.chairs-section {
    background-color: #fff;
   
    .container-fluid {
        .content-wrapper {
            width: 100%;
            height: 100%;
            * {color:#3A3A3A; 
            font-family: sans-serif;
        font-size:xx-large;}
            display: flex;
            align-items: center;
            padding:10%;
            @media #{$max-med} {
                margin-top: 20px;
            }
            p {width: 100%;}
        }
    }

    .carousel-demo {

        .card {
            border: 0;
            background: transparent;

         .product-item {

                .product-item-content {

                    .hover-img-container::before {
                        content: '';
                        position: absolute;
                        bottom: -10%;
                        right: -10%;
                        width: 25%;
                        height: 25%;
                        background-color: transparent;
                        border-bottom: 6px solid #50623A;
                        border-right: 6px solid #50623A;
                        transition: all .7s ease;
                        z-index: 2;
                    }
                    
                    .hover-img-container::after {
                        content: '';
                        position: absolute;
                        top: -10%;
                        left: -10%;
                        width: 25%;
                        height: 25%;
                        background-color: transparent;
                        border-top: 6px solid #50623A;
                        border-left: 6px solid #50623A;
                        transition: all .7s ease;
                        z-index: 2;
                    }
                    
                    .client-hover-img-container {
                        overflow:hidden;
                        position: relative;
                        width: 100%;
                    }
                    
                    .hover-img-container img,
                    .client-hover-img-container img {
                        transition: all 2s;
                        position: relative;
                        display: inline-block;
                    }
                    
                    .hover-img-container:hover img,
                    .client-hover-img-container:hover img {
                        transform: scale3d(1.2,1.2,1.2);
                        z-index: 1;
                    }
                    
                    .hover-img-container:hover::after {
                        top: 20px;
                        left: 20px;
                    }
                    
                    .hover-img-container:hover::before {
                        bottom: 20px;
                        right: 20px;
                    
                    }

                    .product-item-wrapper {
                        width: 90%;
                        max-width: 600px;
                        max-height: 600px;
                        //margin: auto;
                        position:relative;
                        overflow: hidden;
                    }
                        .product-item-details {
                            min-width: 90%;
                             background-color: #ffffff;
                             color: #294B29;
                            margin-bottom: 40px;
                            text-align: center;
                            box-shadow: 0px 8px 25px rgba(211, 211, 211, 0.25);
                            padding: 15px 0;
                            //transform: skewX(-10deg);
                            width: 20%;
                             border-radius: 3px;
                            
                            @media #{$max-med} {
                                padding: 16px;
                            }

                            @media #{$max-small} {
                                padding: 15px;
                            }

                            h4 {
                                margin-top: 10px;
                                color: #304654;
                            }

                            h4, h5 {
                                @media #{$max-med} {
                                    font-size: 14px;
                                }
                                @media #{$max-small} {
                                    font-size: 13px;
                                }
                                @media #{$max-mobile} {
                                    font-size: 12px;
                                }
                            }

                        }

                        .product-image {
                            width: 100%;
                            height: 270px;
                            border-inline-start: 1px solid $part-color;
                        }
                    
                    }
                }

           

            .p-carousel {
                z-index: 1;
            }

            .p-carousel-items-container {

                margin-top: 60px;

                @media #{$min-small} {
                    margin-top: 130px !important;
                }

            }

            .p-carousel-next,
            .p-carousel-prev {
                display: none;
            }

        }

    }

    .caro-bg {
        position: absolute;
        width: 90%;
        right: 0;
        height: 100%;
        background-color: $part-color;
        
        h2 {
            padding-top: 40px;
            @media #{$max-small} {
                margin-inline-start: 40px;
            }
        }

        @media #{$min-med} {
            width: 85%;
            h2 {
                padding-inline-start: 60px;
                padding-top: 60px;
            }
        }

        @media #{$min-large} {
            h2 {
                margin-inline-start: 35px;
            }
        }
        
        
    }

}

.carousel-demo .card .p-carousel-indicators {

    .p-carousel-indicator {
        margin-inline-end: .5rem;
        border: 1px solid transparent;
        padding: 3px;

        button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
        &.p-highlight {
            border: 1px solid $main-color;
            border-radius: 50%;

            button {
                background-color: $main-color;
            }
        }

    }

    @media #{$min-med} {

        .p-carousel-indicator button {
            width: 10px;
            height: 10px;
        }

    }

    @media #{$min-large} {

        padding-bottom: 40px;

        .p-carousel-indicator {

            padding: 4px;

        }

    }

}
.library-page {
    h2 {color: $text-dark}
    .cards-wrapper {
        .card {
            .card-body {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: RobotoBold;
                padding: 0;
                .card-title {font-size: clamp(22px, 3.5vw, 28px);}
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #0000004d;
            }
            img {
                height: 210px;
            }
        }
        .remove-button {
            background-color: #fff;
            top: 0;
            position: absolute;
            padding: 10px
        }
    }
}